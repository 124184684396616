import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="container">
      <div className="row">
        <div className="triangle"></div>
        <div className="triangle"></div>
        <div className="col-12 col-lg-10">
          <h1>Hello<span className="primary">,</span> I'm a <span className="primary">full-stack software engineer</span> based out of San Francisco<span className="primary">.</span></h1>
          <p className="lead font-italic mb-4">
            Currently, I'm an Open Source Engineer at <a href="https://www.slack.com" target="_blank" rel="noreferrer">Slack</a>.
          </p>
          <Link className="btn btn-primary mr-4" to="/projects">Explore Projects</Link>
          <Link className="btn btn-outline-light" to="/contact">Contact Me</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
